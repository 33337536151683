<template>
  <div
    class="ticket-preview"
    :class="layout"
  >
    <PreviewHeader
      :title="ticketTitle"
      :multiplePages="multiplePages"
      :pageCount="pageCount"
      :currentPage="currentPage"
      @updatePage="updatePage"
    />

    <component
      :is="previewBodyRenderComponent"
      :ticket="ticket"
      :bets="displayedBets"
    />

    <PreviewMarketing
      v-if="marketingEnabled"
      :is-visible="isIdle"
    />
  </div>
</template>

<script>
import {
  includes,
  isEmpty,
  startCase,
} from 'lodash';
import { eventBus } from '@/utility';
import PreviewHeader from '@/components/ticket-check/PreviewHeader';
import PreviewMarketing from '@/components/PreviewMarketing';
import config from '../../config';

export default {
  name: 'TicketCheckPreview',
  components: {
    PreviewHeader,
    PreviewBodySport: () => import('@/components/ticket-check/PreviewBodySport'),
    PreviewBodyCommon: () => import('@/components/ticket-check/PreviewBodyCommon'),
    PreviewMarketing,
  },
  data() {
    return {
      activeProduct: null,
      config,
      layout: config.layout,
      ticket: {},
      payloadData: {},
      marketingEnabled: config.marketingEnabled,
      isIdle: false,
      hideInfo: false,
      idleTimeout: null,
      availableProducts: {
        PreMatchBetting: { name: 'PreMatchBetting', type: 'sport' },
        LiveBetting: { name: 'LiveBetting', type: 'sport' },
        SportsbookSM: { name: 'SportsbookSM', type: 'sport' },
      },
      popupActive: false,
      currentPage: 1,
      betItemHeight: 32,
      elementsAroundBetsHeight: 190,
      defaultBetsPerPage: 24,
      ignoredMessages: ['MetaUpdated', 'BetsUpdated'],
    };
  },
  computed: {
    checkedTicketData() {
      return this.$store.getters.checkedTicketData;
    },
    bets() {
      return this.isSportsbookSM ? this.ticket.ticketBets : this.ticket.bets;
    },
    isSportsbookSM() {
      return this.ticket.product === 'SportsbookSM';
    },
    isSystem() {
      return this.ticketType === 2;
    },
    multiplePages() {
      return this.bets?.length > this.betsPerPage;
    },
    pageCount() {
      return Math.ceil(this.bets?.length / this.betsPerPage);
    },
    startingIndexByPage() {
      if (this.currentPage === 1) return 0;
      return this.betsPerPage * (this.currentPage - 1);
    },
    displayedBets() {
      return this.bets?.slice(this.startingIndexByPage, (this.startingIndexByPage + this.betsPerPage));
    },
    activeBets() {
      return this.bets?.length;
    },
    ticketType() {
      const { config: { ticketTypes = {} }, type = '' } = this.ticket;
      const formattedTicketType = ticketTypes?.[type]?.toLowerCase();
      return this.config?.ticketTypes?.[formattedTicketType] ?? startCase(formattedTicketType);
    },
    systemType() {
      if (!this.isSystem) return '';

      if (this.isSportsbookSM) {
        return this.formatSportsbookSMTicketType(this.ticket.ticketUnits);
      }

      return this.ticket.systemType || '';
    },
    ticketTitle() {
      if (!this.activeProduct || isEmpty(this.ticket)) {
        return null;
      }

      return `${this.ticketType} ${this.systemType}`;
    },
    documentHeight() {
      return document.documentElement?.clientHeight;
    },
    betsPerPage() {
      const betsPerPageNumber = Math.floor((this.documentHeight - this.elementsAroundBetsHeight) / this.betItemHeight);
      return betsPerPageNumber || this.defaultBetsPerPage;
    },
    previewBodyRenderComponent() {
      const sportsbookProducts = ['LiveBetting', 'SportsbookSM', 'PreMatchBetting'];
      return `PreviewBody${sportsbookProducts.includes(this.ticket.product) ? 'Sport' : 'Common'}`;
    },
  },
  methods: {
    formatSportsbookSMTicketType(units) {
      if (!units?.length) return '';

      const types = [];

      units.forEach((unit, i) => {
        const parlays = this.ticket.ticketCombinationGroups?.[i]?.parlays;
        const { banker, nonBanker } = this.ticket.ticketUnits?.[i]?.independentBets;

        const type = `${banker}F + ${parlays}/${nonBanker}`;

        types.push(type);
      });

      return types.length > 1 ? types.join(', ') : types[0];
    },
    onPopupChanged(popupActive) {
      this.popupActive = popupActive;
      this.checkIdleState();
    },
    resetData() {
      this.currentPage = 1;
    },
    setCheckedTicket(data) {
      this.resetData();
      this.setTicket(data);
    },
    handleTicketChanged(data) {
      if (this.ignoredMessages.includes(data.action)) return;
      this.$router.replace({ path: '/' }).catch(() => {});
    },
    updatePage() {
      this.currentPage += 1;

      if (this.currentPage > this.pageCount) {
        this.ticket = {};
        this.checkIdleState();
      }
    },
    setTicket(data) {
      if (!data) return;

      this.activeProduct = this.availableProducts[data.productId] || { name: data.productId, type: 'common' };

      // Do nothing in case message is about popup state
      const ticketAction = (data.action || '').toLowerCase();
      if (includes(ticketAction, 'popup')) {
        return;
      }

      if (data.action === 'PreviewClosed') {
        this.$router.push({ name: 'TicketPreviewHome' }).catch(() => {});
        return;
      }

      this.ticket = data.ticket || {};
      this.ticket.config = this.ticket.config || {};
      if (!this.multiplePages) {
        this.checkIdleState();
        return;
      }

      this.isIdle = false;

      if (this.idleTimeout) {
        clearTimeout(this.idleTimeout);
        this.idleTimeout = null;
      }
    },
    rescheduleIdle() {
      // clear previous timeout
      if (this.idleTimeout) {
        clearTimeout(this.idleTimeout);
        this.idleTimeout = null;
      }

      // schedule new timeout
      const idleTimeoutInMs = this.config.idleTimeout * 1000;

      this.idleTimeout = setTimeout(() => {
        this.isIdle = true;
        this.resetData();
      }, idleTimeoutInMs);
    },
    checkIdleState() {
      if (this.hideInfo) {
        this.isIdle = true;
        this.resetData();
        return;
      }

      if (this.activeBets || this.popupActive) {
        this.deactivateIdleState();
      }

      this.rescheduleIdle();
    },
    deactivateIdleState() {
      if (!this.hideInfo) {
        this.isIdle = false;
      }

      this.rescheduleIdle();
    },
    onModeChanged(data) {
      this.hideInfo = data.mode === 'hidden';
      this.checkIdleState();
    },
    onUserChanged(data) {
      if (data.action !== 'LoggingOut') return;

      this.ticket = {};
    },
    setListeners() {
      eventBus.$on('TicketChanged', this.handleTicketChanged);
    },
  },
  mounted() {
    this.setListeners();

    if (!this.multiplePages) {
      this.checkIdleState();
    }
  },
  watch: {
    checkedTicketData: {
      immediate: true,
      handler(newVal) {
        this.setCheckedTicket(newVal);
      },
    },
  },
  destroyed() {
    this.currentPage = 1;
  },
};
</script>

<style lang="scss" scoped>
.ticket-preview {
  position: relative;
  height: 100vh;
  overflow: hidden;
  user-select: none;
  padding: 0;

  .clearfix:after {
    content: '';
    display: table;
    clear: both;
  }
}
</style>
